import React from 'react';
import { H2, H3, P } from './style';

const Texts = () => (
  <div>
    <H2>Textes</H2>
    <H3>Paragraphe</H3>
    <P>
      Lucas ipsum dolor sit amet lars grievous maul ben gamorrean ewok darth antilles yoda darth.
      Yavin lando amidala c-3po jango maul vader dooku. Organa ewok endor vader jade secura hoth zabrak.
      Hutt solo aayla tatooine ackbar windu. Wedge jabba skywalker darth mon mon. C-3po bespin kamino leia
      yoda darth. Mothma ahsoka obi-wan qui-gon wicket moff. Skywalker leia calrissian jinn obi-wan dagobah.
      Droid mothma jawa windu padmé han calrissian solo. Utapau sebulba dooku skywalker r2-d2 wicket darth
      tusken raider moff.
    </P>
  </div>
);

export default Texts;
