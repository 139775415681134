import React from 'react';
import styled from 'styled-components';
import { colors, margins } from '../../components/home/v2/StyledComponents';
import { H2, H3 } from './style';

const P = styled.p`
  width: 500px;
  text-align: justify;
  background-color: ${colors.navy};
  border-color: ${colors.navy};
  border-width: 1px;
  border: solid;
`;

const S = styled(P)`margin-top: ${margins.s};`;
const M = styled(P)`margin-top: ${margins.m};`;
const L = styled(P)`margin-top: ${margins.l};`;

const Margins = () => (
  <div>
    <H2>Marges</H2>

    <H3>Marges de 15px </H3>
    <P>
      <br />
    </P>
    <S>
      <br /><br />
    </S>

    <H3>Marges de 30px </H3>
    <P>
      <br />
    </P>
    <M>
      <br /><br />
    </M>

    <H3>Marges de 44px </H3>
    <P>
      <br />
    </P>
    <L>
      <br /><br />
    </L>
  </div>
);

export default Margins;
