import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mobileThresholdPixels } from './styledComponents';

const ModalContent = styled.div`
  padding: 20px 20px 0px;
  background-color: white;
  min-width: 300px;
  max-width: 700px;
  margin: 50px auto;
  z-index: 3;
  position: relative;

  @media (max-width: ${mobileThresholdPixels}) {
    min-width: 100px;
    width: calc(98vw - 40px);
  }
`;

const ModalContainer = styled.div`
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  z-index: 3;

  @media (max-width: ${mobileThresholdPixels}) {
    top: -20px;
    min-height: calc(100vh + 20px);
  }
`;

const ModalShadow = styled.div`
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  z-index: 2;
  background-color: rgba(0,0,0,0.35);
  top: 0;
  left: 0;
  ${props => props.backgroundImage && `background: url(${props.backgroundImage});`}
  background-size: cover;
  background-position: center;
`;

const Modal = ({ show, children, backgroundImage }) => {
  if (!show) {
    return null;
  }
  return (
    <div>
      <ModalShadow backgroundImage={backgroundImage} />
      <ModalContainer>
        <ModalContent>
          {children}
        </ModalContent>
      </ModalContainer>
    </div>
  );
};

Modal.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node.isRequired,
  backgroundImage: PropTypes.string,
};

Modal.defaultProps = {
  show: false,
  backgroundImage: null,
};

export default Modal;
