import React from 'react';
import styled from 'styled-components';
import { H2, H3, H4 } from './style';
import { colors } from '../../components/home/v2/StyledComponents';
import logoNavy from '../../assets/logos/tilli.navy.svg';
import logoWhite from '../../assets/logos/tilli.white.svg';

const LogoContainer = styled.div`
  background-color: ${props => props.invertedColor ? colors.navy : colors.white};
  width: fit-content;
  padding: 2px;
  margin: 2px;
`;

const Logo = styled.img`
  width: 600px;
`;

const Logos = () => (
  <div>
    <H2>Logos</H2>
    <H3>Logo principal</H3>
    <LogoContainer>
      <Logo src={logoNavy} alt="logo principal bleu" />
    </LogoContainer>
    <H4>Alternative</H4>
    <LogoContainer invertedColor>
      <Logo src={logoWhite} alt="logo principal blanc" />
    </LogoContainer>
  </div>
);

export default Logos;
