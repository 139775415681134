import styled from 'styled-components';
import { fontSizes, margins, fontWeight, colors } from '../home/v2/StyledComponents';

export const H1 = styled.h1`
  font-size: ${fontSizes.x3l};
  font-weight: 200;
`;

export const H2 = styled.h2`
  margin: ${margins.l} 0px;
  font-size: ${fontSizes.x2l};
  font-weight: 200;
`;

export const H3 = styled.h3`
  margin: ${margins.xs} 0px;
  font-size: ${fontSizes.xl};
  font-weight: 200;
`;

export const H4 = styled.h4`
  margin: ${margins.xs} 0px;
  font-size: ${fontSizes.xl};
  font-weight: 200;
`;

export const P = styled.p`
  font-size: ${fontSizes.m};
  font-weight: ${fontWeight.light};
  color: ${colors.navy};
  line-height: 22px;
  text-align: justify;
`;
