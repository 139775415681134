import React from 'react';
import { H2, P } from './style';

const Comments = () => (
  <div>
    <H2>Commentaires Généraux</H2>
    <P>
      Le design actuel a une largeur fixe de 1180px sur desktop
      qui est également le  threshold en px pour passer
      du design desktop au design mobile (vice-versa).
    </P>
  </div>
);

export default Comments;
